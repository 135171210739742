import { Injectable, Type } from '@angular/core';
import { CapturumDialogService } from '@capturum/ui/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  constructor(private dialogService: CapturumDialogService) {}

  public open(component: Type<any>, config?: DynamicDialogConfig): DynamicDialogRef {
    return this.dialogService.open(component, config, SidebarComponent);
  }

  public close(): void {
    this.dialogService.dialogComponentRefMap.forEach((dialogRef) => {
      if (dialogRef.componentType === SidebarComponent) {
        dialogRef.destroy();
      }
    });
  }

  public setTitle(title: string): void {
    this.dialogService.dialogComponentRefMap.forEach((dialogRef) => {
      if (dialogRef.componentType === SidebarComponent) {
        (dialogRef.instance as unknown as SidebarComponent).title = title;
      }
    });
  }
}
