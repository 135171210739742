<p-sidebar
  styleClass="metis-sidebar"
  #sidebar
  [(visible)]="sidebarVisible"
  [position]="'right'"
  (onHide)="handleHideSidebar(sidebar)">
  <ng-template pTemplate="header">
    {{ title }}
  </ng-template>

  <ng-template capDynamicDialogContent></ng-template>
</p-sidebar>
