<div class="popup-content p-0 d-flex justify-content-center">
  <div *ngIf="warnings.length">
    <div *ngFor="let warning of warnings" class="warning">
      <i class="me-2 fas fa-exclamation-triangle"></i>
      <h4 *ngIf="warning">{{ warning }}</h4>
    </div>
  </div>
</div>

<div class="popup-actions">
  <cap-button icon="fas fa-times" styleClass="primary" [label]="'button.cancel' | translate" (onClick)="onClose(false)">
  </cap-button>

  <cap-button
    appPreventDoubleClick
    icon="fas fa-stop"
    styleClass="danger"
    [label]="'hpm.order-operations.open-box.order-details.stop.button' | translate"
    (clickEvent)="submit(true)">
  </cap-button>
</div>
