import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { DestroyBase } from '@capturum/shared';
import { LocalStorageService, SidebarMenuItem } from '@capturum/ui/api';
import { SetOccupationPopupComponent } from '@shared/components/set-occupation-popup/set-occupation-popup.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { StationStateService } from '@shared/services/station-state.service';
import { first } from 'rxjs/operators';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';
import { AddDisruptionsDialogComponent } from '../../../features/operator/order-operations/components/add-disruptions-dialog/add-disruptions-dialog.component';
import { OrderOperationService } from '@shared/services/order-operation.service';
import { ListRendererService } from '@capturum/builders/list-renderer';
import { switchMap } from 'rxjs';

export interface ExtraSidebarMenuItem {
  class: string;
  routerLink?: string;
  callback: () => void;
}

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationBarComponent extends DestroyBase implements OnInit {
  public navItems: SidebarMenuItem[] = [];
  public innerWidth = window.innerWidth;
  public stationId: string;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private stationStateService: StationStateService,
    private localStorageService: LocalStorageService,
    private listRendererService: ListRendererService,
    public orderOperationService: OrderOperationService,
  ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.innerWidth = window.innerWidth;
  }

  public ngOnInit(): void {
    this.stationId = this.stationStateService.getStation()?.id;
    this.navItems = this.getAllMenuItems();
  }

  public executeAction(button: SidebarMenuItem & ExtraSidebarMenuItem): void {
    if (button.callback) {
      button.callback();
    }
  }

  private getAllMenuItems(): (SidebarMenuItem & ExtraSidebarMenuItem)[] {
    return [
      {
        label: 'Start',
        icon: 'fas fa-play',
        class: 'custom-button-1',
        routerLink: null,
        hidden: true,
        callback: () => {},
      },
      {
        label: 'hpm.sidebar.occupation.label',
        icon: 'fas fa-user-friends',
        class: 'occupation-button',
        routerLink: null,
        hidden: !this.stationId,
        callback: () => {
          return this.openOccupationPopup();
        },
      },
      {
        label: 'hpm.sidebar.disruption.label',
        icon: 'fas fa-exclamation-triangle',
        class: 'disruption-button',
        routerLink: null,
        hidden: !this.stationId,
        callback: () => {
          return this.openDisruptionPopup();
        },
      },
    ];
  }

  private openOccupationPopup(): void {
    const dialogRef = this.dialogService.open(SetOccupationPopupComponent, {
      header: this.translateService.instant('hpm.set-occupation.title'),
      styleClass: 'responsive-dialog padding-0 set-occupation',
    });

    dialogRef.onClose.pipe(first()).subscribe((occupation) => {
      if (occupation) {
        this.localStorageService.setItem(LocalStorageKey.initialOccupation, {
          isInitialOccupation: false,
          fte: occupation.occupants_sum,
        });

        this.listRendererService.refreshTable();
      }
    });
  }

  private openDisruptionPopup(): void {
    const dialogRef = this.dialogService.open(AddDisruptionsDialogComponent, {
      header: this.translateService.instant('hpm.order-operations.open-box.order-details.pause.title'),
      styleClass: 'add-disruptions',
      data: {
        globalAction: true,
      },
    });

    this.orderOperationService
      .globalPauseOperation()
      .pipe(
        switchMap(() => {
          return dialogRef.onClose;
        }),
      )
      .subscribe(() => {
        this.listRendererService.refreshTable();
      });
  }
}
