import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';
import { StationStateService } from '@shared/services/station-state.service';

@Injectable({
  providedIn: 'root',
})
export class HttpCodeInterceptor implements HttpInterceptor {
  constructor(private router: Router, private stationStateService: StationStateService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (error) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 500:
                if (error.error.message === 'The given station could not be instantiated.') {
                  this.stationStateService.removeStation();
                  this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
                }

                break;
              default:
                break;
            }
          }
        },
      ),
    );
  }
}
