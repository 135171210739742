<cap-sidebar-menu
  [class.hide]="innerWidth < 601"
  [expandable]="false"
  [homePageUrl]="'order-operations'"
  [logoUrl]="'./assets/images/emendis-logo.png'"
  [menus]="navItems">
  <ng-template capTemplate="item" let-item="item">
    <cap-button
      styleClass="w-100"
      [class]="item.class"
      [class.hidden]="item.hidden"
      [icon]="item.icon"
      [label]="item.label | translate"
      (onClick)="executeAction(item)">
    </cap-button>
  </ng-template>
</cap-sidebar-menu>
