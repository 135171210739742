import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { OrderOperation, OrderOperationDisruptions } from '@core/interfaces/order-operation.interface';
import { responseData } from '@capturum/builders/core';

@Injectable({
  providedIn: 'root',
})
export class OrderOperationService extends ApiService<OrderOperation> {
  protected endpoint = 'order-operation';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public startOperation(
    data: { started_at: Date },
    order_operation_id: string,
    options?: ListOptions,
  ): Observable<OrderOperation> {
    return this.apiHttp
      .patch(`/${this.endpoint}/${order_operation_id}/start${ApiService.generateQuery(options)}`, data)
      .pipe(responseData);
  }

  public pauseOperation(order_operation_id: string): Observable<OrderOperation> {
    return this.apiHttp.patch(`/${this.endpoint}/${order_operation_id}/pause`, {});
  }

  public globalPauseOperation(): Observable<void> {
    return this.apiHttp.patch(`/${this.endpoint}/pause`, {});
  }

  public onHoldOperation(order_operation_id: string): Observable<OrderOperation> {
    return this.apiHttp.patch(`/${this.endpoint}/${order_operation_id}/on-hold`, {}).pipe(responseData);
  }

  public resumeOnHoldOperation(order_operation_id: string): Observable<OrderOperation> {
    return this.apiHttp.patch(`/${this.endpoint}/${order_operation_id}/resume-on-hold`, {}).pipe(responseData);
  }

  public stopOperation(
    data: { finished_at: Date; _force?: boolean },
    order_operation_id: string,
    options?: ListOptions,
  ): Observable<OrderOperation> {
    return this.apiHttp.patch(`/${this.endpoint}/${order_operation_id}/end${ApiService.generateQuery(options)}`, data);
  }

  public resumeOperation(
    data: {
      remark: string;
      disruption_value_id: string;
    },
    order_operation_id: string,
  ): Observable<OrderOperation> {
    return this.apiHttp.patch(`/${this.endpoint}/${order_operation_id}/resume`, data);
  }

  public globalResumeOperation(data: { remark: string; disruption_value_id: string }): Observable<void> {
    return this.apiHttp.patch(`/${this.endpoint}/resume`, data);
  }

  public cancelOperation(order_operation_id: string): Observable<OrderOperation> {
    return this.apiHttp.patch(`/${this.endpoint}/${order_operation_id}/cancel`, {});
  }

  public globalCancelOperation(): Observable<OrderOperation> {
    return this.apiHttp.patch(`/${this.endpoint}/cancel`, {});
  }

  public getOrderOperationDisruptions(order_operation_id: string): Observable<OrderOperationDisruptions[]> {
    return this.apiHttp.get(`/${this.endpoint}/${order_operation_id}/disruptions`);
  }
}
