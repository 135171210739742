import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, takeUntil } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs/operators';
import { PublicTranslationService } from '@capturum/complete';
import { DestroyBase } from '@capturum/shared';

@Injectable({ providedIn: 'root' })
export class MetisPublicTranslationResolver extends DestroyBase {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly translateService: TranslateService,
    private readonly publicTranslateService: PublicTranslationService,
  ) {
    super();
  }

  public setPublicTranslations(): Observable<any> {
    return combineLatest([
      this.publicTranslateService.loadTranslations(),
      this.httpClient.get('assets/i18n/en.json'),
    ]).pipe(
      tap(([publicTranslations, localTranslations]) => {
        this.translateService.setTranslation('en', localTranslations, true);
      }),
      takeUntil(this.destroy$),
    );
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.setPublicTranslations().pipe(
      tap(() => {
        return this.translateService.use('en');
      }),
      map(Boolean),
    );
  }
}
