import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-count-timer',
  standalone: true,
  template: `
    <div>{{ hours | number : '2.0-0' }}:{{ minutes | number : '2.0-0' }}:{{ seconds | number : '2.0-0' }}</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [DecimalPipe],
})
export class CountTimerComponent {
  @Input() public elapsedTimeInSeconds = 0;

  public get hours(): number {
    return Math.floor(this.elapsedTimeInSeconds / 3600);
  }

  public get minutes(): number {
    return Math.floor((this.elapsedTimeInSeconds % 3600) / 60);
  }

  public get seconds(): number {
    return this.elapsedTimeInSeconds % 60;
  }
}
