import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-weight-widget',
  standalone: true,
  imports: [CommonModule],
  template: ` <div>{{ item?.weight | number : '1.2' }} KG</div>`,
})
export class WeightWidgetComponent extends ListRendererWidgetBase {}
