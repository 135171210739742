import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CapturumFormRendererComponent,
  CapturumFormRendererModule,
  FormRendererService,
} from '@capturum/builders/form-renderer';
import { BuildersFormKey } from '@core/enums/builders-key.enum';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { SidebarLayoutComponent } from '@shared/components/sidebar-layout/sidebar-layout.component';
import { OrderOperationService } from '@shared/services/order-operation.service';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { SidebarService } from '@shared/services/sidebar.service';
import { CapturumDialogService } from '@capturum/ui/api';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { SharedModule } from '@shared/shared.module';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OrderOperationDisruptions } from '@core/interfaces/order-operation.interface';
import { StationStateService } from '@shared/services/station-state.service';
import { AddCompletedOrderOperationDisruptionsComponent } from '../../components/add-completed-order-operation-disruptions/add-completed-order-operation-disruptions.component';
import { FormDividerTitleComponent } from '@shared/components/form-divider-title/form-divider-title.component';

@Component({
  selector: 'app-view-process-order',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CapturumFormRendererModule,
    CapturumButtonModule,
    SidebarLayoutComponent,
    CapturumListRendererModule,
    CapturumSkeletonModule,
    SharedModule,
    FormDividerTitleComponent,
  ],
  styleUrls: ['./view-process-order.component.scss'],
  templateUrl: './view-process-order.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ViewProcessOrderComponent implements OnInit {
  public orderDetailsKey: BuildersFormKey = BuildersFormKey.formOrderDetails;
  public orderOperationDetailKey: BuildersFormKey = BuildersFormKey.formOrderOperationDetails;
  @ViewChild('orderOperationForm')
  public orderOperationForm: CapturumFormRendererComponent;

  public orderOperationId: string;
  public stationId: string;
  public loading$: Observable<boolean>;
  public disruptions$: Observable<OrderOperationDisruptions[]>;

  constructor(
    public config: DynamicDialogConfig,
    public orderOperationService: OrderOperationService,
    private actionService: CapturumBuilderActionService,
    private sidebarService: SidebarService,
    private dialogService: CapturumDialogService,
    private formRendererService: FormRendererService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private stationStateService: StationStateService,
  ) {}

  public ngOnInit(): void {
    this.stationId = this.stationStateService.getStation().id;
    this.orderOperationId = this.config?.data?.item?.id;
    this.loading$ = this.formRendererService.getSourceValueByKey(this.orderDetailsKey);

    this.getDisruptions();
  }

  public addDisruption(): void {
    const dialogRef = this.dialogService.open(AddCompletedOrderOperationDisruptionsComponent, {
      header: this.translateService.instant('hpm.order-operations.view-box.add-disruption.title'),
      styleClass: 'add-completed-order-operation-disruptions',
      data: {
        orderOperationId: this.orderOperationId,
        stationId: this.stationId,
      },
    });

    dialogRef.onClose.pipe(first()).subscribe((res) => {
      this.getDisruptions();
      this.cdr.detectChanges();
    });
  }

  public getDisruptions(): void {
    this.disruptions$ = this.orderOperationService.getOrderOperationDisruptions(this.orderOperationId);
  }

  public editDisruption(disruptionId: string): void {
    const dialogRef = this.dialogService.open(AddCompletedOrderOperationDisruptionsComponent, {
      header: this.translateService.instant('hpm.order-operations.view-box.edit-disruption.title'),
      styleClass: 'add-completed-order-operation-disruptions',
      data: {
        stationId: this.stationId,
        orderOperationId: this.orderOperationId,
        disruptionId,
      },
    });

    dialogRef.onClose.pipe(first()).subscribe(() => {
      this.getDisruptions();
      this.cdr.detectChanges();
    });
  }
}
