<app-dialog-layout [loading]="loading$ | async">
  <ng-container content>
    <cpb-form-renderer [formKey]="formKey" [showHeader]="false"></cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button
      icon="fas fa-times"
      styleClass="popup-button cancel-button"
      [label]="'button.cancel' | translate"
      (onClick)="cancel()">
    </cap-button>

    <cap-button
      icon="fas fa-check"
      styleClass="popup-button save-button"
      [label]="'hpm.button.save' | translate"
      (onClick)="submit()">
    </cap-button>
  </ng-container>

  <ng-container skeleton>
    <div class="row w-100 mt-3">
      <div class="col-6">
        <cap-skeleton height="58px"></cap-skeleton>
      </div>
      <div class="col-6">
        <cap-skeleton height="58px"></cap-skeleton>
      </div>
    </div>

    <div class="row w-100 mt-3">
      <div class="col">
        <cap-skeleton height="100px"></cap-skeleton>
      </div>
    </div>

    <div class="row w-100 mt-5 d-flex justify-content-between">
      <div class="col-2">
        <cap-skeleton height="60px"></cap-skeleton>
      </div>

      <div class="col-2">
        <cap-skeleton height="60px"></cap-skeleton>
      </div>
    </div>
  </ng-container>
</app-dialog-layout>
