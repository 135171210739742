import { Component, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumButtonModule } from '@capturum/ui/button';
import { SharedModule } from '@shared/shared.module';
import { OrderOperationService } from '@shared/services/order-operation.service';
import { DialogLayoutComponent } from '@shared/components/dialog-layout/dialog-layout.component';
import { DoubleClickDirective } from '@shared/directives/double-click.directive';

@Component({
  selector: 'app-stop-order-operation-popup',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CapturumButtonModule,
    SharedModule,
    DialogLayoutComponent,
    DoubleClickDirective,
  ],
  templateUrl: './stop-order-operation-popup.component.html',
  styleUrls: ['./stop-order-operation-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StopOrderOperationPopupComponent {
  public warnings: string[] = [];
  private finished_at: Date;
  private orderOperationId: string;

  constructor(
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    public orderOperationService: OrderOperationService,
  ) {
    const { orderOperationId, finished_at, warnings } = this.config.data;

    this.orderOperationId = orderOperationId;
    this.finished_at = finished_at;
    this.warnings = warnings;
  }

  public onClose(closeSidebar: boolean): void {
    this.dialogRef.close(closeSidebar);
  }

  public submit(force: boolean): void {
    this.orderOperationService
      .stopOperation(
        {
          finished_at: this.finished_at,
          _force: force,
        },
        this.orderOperationId,
      )
      .subscribe(() => {
        this.onClose(true);
      });
  }
}
