import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StationStateService } from '@shared/services/station-state.service';

@Injectable()
export class StationInterceptor implements HttpInterceptor {
  constructor(private stationStateService: StationStateService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.stationStateService.getStation()?.id ? this.addStationIdHeader(request, next) : next.handle(request);
  }

  private addStationIdHeader(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const stationId = this.stationStateService.getStation()?.id;
    const modifiedRequest = request.clone({
      setHeaders: {
        'station-id': stationId,
      },
    });

    return next.handle(modifiedRequest);
  }
}
