import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { CommonModule } from '@angular/common';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { QualityInspection } from '@core/interfaces/quality-inspection.interface';

@Component({
  selector: 'app-edit-quality-inspections',
  standalone: true,
  imports: [CommonModule],
  template: `<i class="fas fa-pencil" (click)="editQualityInspection(item)"></i>`,
  styleUrls: ['./edit-quality-inspections.component.scss'],
})
export class EditQualityInspectionsComponent extends ListRendererWidgetBase {
  constructor(private actionService: CapturumBuilderActionService) {
    super();
  }

  public editQualityInspection(qualityInspection: QualityInspection): void {
    this.actionService.broadcastActionExecuted(
      { key: 'edit', type: 'on_column_click', options: {} },
      qualityInspection,
      null,
    );
  }
}
