<app-sidebar-layout [loading]="loading$ | async">
  <ng-container content>
    <div class="order-details">
      <app-form-divider-title
        [icon]="'fa fa-info-circle'"
        [title]="'hpm.order-operations.open-box.order-details.title'">
      </app-form-divider-title>

      <div class="content">
        <cpb-form-renderer [formKey]="orderDetailsKey" [modelId]="config?.data?.item?.order_id" [showHeader]="false">
        </cpb-form-renderer>

        <ng-container *ngIf="orderOperation$ | async as orderOperation">
          <cap-button
            data-test="quality-inspection-button"
            icon="fas fa-star"
            [class.pe-none]="!orderOperation?.has_quality_inspections"
            [disabled]="!orderOperation?.has_quality_inspections"
            [label]="'hpm.order-operations.open-box.order-details.quality-inspection.button' | translate"
            (onClick)="qualityInspection()">
          </cap-button>
        </ng-container>

        <cap-button
          data-test="quality-deviation"
          icon="fas fa-edit"
          [label]="'hpm.order-operations.open-box.order-details.quality-deviation.button' | translate"
          (onClick)="qualityDeviation()">
        </cap-button>

        <cap-button
          data-test="view-components"
          icon="fas fa-file-alt"
          [label]="'hpm.order-operations.open-box.order-details.view-components.button' | translate"
          (onClick)="viewComponents()">
        </cap-button>
      </div>
    </div>

    <div class="order-duration mt-4">
      <app-form-divider-title [icon]="'fa fa-clock'" [title]="'hpm.order-operations.open-box.duration.title'">
      </app-form-divider-title>

      <div class="content">
        <cpb-form-renderer
          #orderOperationForm
          [formKey]="orderOperationDetailKey"
          [modelId]="config?.data?.item?.id"
          [showHeader]="false">
        </cpb-form-renderer>
      </div>
    </div>

    <ng-container *ngIf="disruptions$ | async as disruptions">
      <div *ngIf="disruptions?.length" class="disruptions mt-3">
        <app-form-divider-title
          [icon]="'fa fa-triangle-exclamation'"
          [title]="'hpm.order-operations.open-box.disruption.title'">
        </app-form-divider-title>

        <div *ngFor="let disruption of disruptions" class="content disruption">
          <div class="row">
            <div class="disruption-title">
              <span><i class="{{ disruption.icon }} me-2 disruption-icon"></i>{{ disruption.title }}</span>
            </div>
            <div class="disruption-value">
              <span>{{ disruption.details }}</span>
            </div>
          </div>

          <div class="row">
            <div class="disruption-title">
              <span>{{ 'hpm.order-operations.open-box.order-details.disruption.duration.label' | translate }}</span>
            </div>
            <div class="disruption-value">
              <span>{{ disruption.duration }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container buttons>
    <div *ngIf="!startedOrderOperation" class="d-flex justify-content-end">
      <cap-button
        class="start-button"
        data-test="start"
        icon="fas fa-play"
        [label]="'hpm.order-operations.open-box.order-details.start.button' | translate"
        (onClick)="start()">
      </cap-button>
    </div>

    <div *ngIf="startedOrderOperation && !isOnHoldProcess" class="d-flex justify-content-end">
      <cap-button
        class="on-hold-button"
        data-test="on-hold"
        icon="fas fa-pause"
        [label]="'hpm.order-operations.open-box.order-details.on-hold.button' | translate"
        (onClick)="onHold()">
      </cap-button>

      <cap-button
        class="pause-button"
        data-test="pause"
        icon="fas fa-pause"
        [label]="'hpm.order-operations.open-box.order-details.pause.button' | translate"
        (onClick)="pause()">
      </cap-button>

      <cap-button
        class="stop-button"
        data-test="stop"
        icon="fas fa-stop"
        [label]="'hpm.order-operations.open-box.order-details.stop.button' | translate"
        (onClick)="stop()">
      </cap-button>
    </div>

    <div *ngIf="startedOrderOperation && isOnHoldProcess" class="d-flex justify-content-end">
      <cap-button
        class="resume-button"
        data-test="resume"
        icon="fas fa-play"
        [label]="'hpm.order-operations.open-box.order-details.resume-on-hold.button' | translate"
        (onClick)="resumeOnHoldOperation()">
      </cap-button>
    </div>
  </ng-container>

  <ng-container skeleton>
    <div *ngFor="let card of [1, 2, 3, 4]" class="row w-100 mt-3">
      <cap-skeleton height="45px" width="448px"></cap-skeleton>
    </div>

    <div *ngFor="let button of [1, 2, 3]" class="row w-100 mt-3">
      <cap-skeleton height="46px" width="230px"></cap-skeleton>
    </div>

    <div *ngFor="let card of [1, 2, 3, 4]" class="row w-100 mt-3">
      <cap-skeleton height="45px" width="448px"></cap-skeleton>
    </div>
  </ng-container>
</app-sidebar-layout>
