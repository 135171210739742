import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PermissionGuard, TranslationLoader } from '@capturum/complete';
import { CoreModule } from '@core/core.module';
import { ConfirmationService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInjector } from './app-injector.service';
import { CapturumToastModule } from '@capturum/ui/toast';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { NgxsModule } from '@ngxs/store';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { AuthService as CapturumAuthService } from '@capturum/auth';
import { AuthService } from '@shared/services/auth.service';
import { ViewDetailsWidgetComponent } from '@core/builders/list-widgets/view-details-widget/view-details-widget.component';
import { LoginService as CapturumLoginService } from '@capturum/login';
import { LoginService } from '@shared/services/login.service';
import { OpenSidebarAction } from '@core/builders/actions/open-sidebar.action';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StationInterceptor } from '@core/interceptors/station.interceptor';
import { WeightWidgetComponent } from '@core/builders/list-widgets/weight-widget/weight-widget.component';
import { CompletedWidgetComponent } from '@core/builders/list-widgets/completed-widget/completed-widget.component';
import { EmptyAction } from '@core/builders/actions/empty.action';
import { EditQualityInspectionsComponent } from './features/operator/order-operations/components/edit-quality-inspections/edit-quality-inspections.component';
import { AddDisruptionTypeComponent } from './features/admin/disruptions/components/add-disruption-type/add-disruption-type.component';
import { DisruptionTypeIconWidgetComponent } from '@core/builders/list-widgets/disruption-type-icon-widget/disruption-type-icon-widget.component';
import { AddDisruptionTypeValueComponent } from './features/admin/disruptions/components/add-disruption-type-value/add-disruption-type-value.component';
import { OnHoldWidgetComponent } from '@core/builders/list-widgets/on-hold-widget/on-hold-widget.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    CoreModule,
    CapturumToastModule,
    CapturumListRendererModule.forRoot({
      widgets: {
        viewDetails: ViewDetailsWidgetComponent,
        weight: WeightWidgetComponent,
        completed: CompletedWidgetComponent,
        editQualityInspection: EditQualityInspectionsComponent,
        disruptionTypeIcon: DisruptionTypeIconWidgetComponent,
        onHold: OnHoldWidgetComponent,
      },
    }),
    CapturumFormRendererModule.forRoot({
      translationKeyPrefix: '',
    }),
    ConfirmDialogModule,
    CapturumButtonModule,
    CapturumBuilderCoreModule.forRoot({
      baseUrl: '/builders',
      actions: {
        open_sidebar: OpenSidebarAction,
        empty: EmptyAction,
      },
      components: {
        list_admin_disruption_types_add: AddDisruptionTypeComponent,
        list_admin_disruption_types_edit: AddDisruptionTypeComponent,
        list_admin_disruption_type_values_add_type_value: AddDisruptionTypeValueComponent,
        list_admin_disruption_type_values_view: AddDisruptionTypeValueComponent,
        list_admin_disruption_type_values_edit: AddDisruptionTypeValueComponent,
      },
    }),
    NgxsModule.forRoot(),
  ],
  providers: [
    ConfirmationService,
    PermissionGuard,
    {
      provide: CapturumAuthService,
      useClass: AuthService,
    },
    {
      provide: CapturumLoginService,
      useClass: LoginService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
