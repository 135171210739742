import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogLayoutComponent } from '@shared/components/dialog-layout/dialog-layout.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormModule } from '@capturum/formly';
import { SharedModule } from '@shared/shared.module';
import { BuildersFormKey, BuildersListKey } from '@core/enums/builders-key.enum';
import { CapturumFormRendererModule, FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { filter, first, map } from 'rxjs/operators';
import { Observable, of, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { DestroyBase } from '@capturum/shared';

@Component({
  selector: 'app-quality-inspection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogLayoutComponent,
    FormModule,
    SharedModule,
    CapturumFormRendererModule,
    CapturumSkeletonModule,
    CapturumListRendererModule,
  ],
  templateUrl: './quality-inspection-dialog.component.html',
  styleUrls: ['./quality-inspection-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QualityInspectionDialogComponent extends DestroyBase implements OnInit, AfterViewInit {
  public formKey: BuildersFormKey = BuildersFormKey.formQualityInspection;
  public listKey: BuildersListKey = BuildersListKey.listQualityInspectionResults;
  public loading$: Observable<boolean> = of(true);
  public orderOperationId: string;
  public viewQuality = false;
  public editQuality = false;
  public addQuality = true;
  public modelId: string;

  constructor(
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formRendererService: FormRendererService,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private actionService: CapturumBuilderActionService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.orderOperationId = this.config?.data?.orderOperationId;

    this.loading$ = this.formRendererService.getFormConfigurationByKey(this.formKey).pipe(
      filter(Boolean),
      first(),
      map(() => {
        return false;
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.actionService
      .getActionExecutionByActionKey('edit')
      .pipe(takeUntil(this.destroy$))
      .subscribe((qualityInspection) => {
        this.handleEditAction(qualityInspection);
      });
  }

  public cancel(): void {
    if (this.addQuality || this.viewQuality) {
      this.dialogRef.close(true);
    } else if (this.editQuality) {
      this.editQuality = false;
      this.addQuality = false;
      this.viewQuality = true;

      this.showQualityInspections();
    }
  }

  public submit(): void {
    this.formSaverService.submit(this.formKey).subscribe(() => {
      this.toastService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('hpm.order-operations.open-box.quality-inspection.save.success'),
      );

      this.cancel();
    });
  }

  public showQualityInspections(): void {
    this.config.styleClass = 'view-quality-inspections';
    this.config.header = this.translateService.instant(
      'hpm.order-operations.open-box.quality-inspection.view-quality-inspections.title',
    );
  }

  public editQualityInspections(): void {
    this.config.styleClass = 'quality-inspection';
    this.config.header = this.translateService.instant('hpm.order-operations.open-box.quality-inspection.title');
  }

  public viewQualityInspections(): void {
    this.viewQuality = true;
    this.addQuality = false;
    this.editQuality = false;

    this.showQualityInspections();
  }

  public returnToAddQualityForm(): void {
    this.viewQuality = false;
    this.editQuality = false;
    this.addQuality = true;
    this.modelId = null;

    this.editQualityInspections();
  }

  private handleEditAction(qualityInspection): void {
    this.editQuality = true;
    this.addQuality = false;
    this.viewQuality = false;
    this.modelId = qualityInspection.item.id;

    this.editQualityInspections();
  }
}
