import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { User } from '@capturum/complete';
import { AppRoutes } from '@core/enums/routes.enum';
import { MenuItem as CompleteMenuItem } from 'primeng/api';
import { first } from 'rxjs/operators';
import { ApiHttpService } from '@capturum/api';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from '@environments/environment';
import { from, Observable } from 'rxjs';

export interface MenuItem extends CompleteMenuItem {
  hidden?: Observable<boolean> | boolean;
}

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  public menuItems: MenuItem[];
  public user: User;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private apiHttpService: ApiHttpService,
    private ngxPermissionService: NgxPermissionsService,
  ) {
    this.user = this.authService.getUser();
  }

  public ngOnInit(): void {
    this.menuItems = this.getMenuItems();
  }

  public executeCommand(item: MenuItem): void {
    if (item.command) {
      item.command(null);
    } else if (item.routerLink) {
      this.router.navigateByUrl(item.routerLink);
    }
  }

  public switchUser(): void {
    this.apiHttpService.post('/auth/logout/switch', {}).subscribe(() => {
      this.router.navigate(['/', AppRoutes.auth, AppRoutes.operatorLogin]);
    });
  }

  private getMenuItems(): MenuItem[] {
    const adminPermission = !!this.ngxPermissionService.getPermission('hpm.admin.manage');
    const operatorPermission = !!this.ngxPermissionService.getPermission('hpm.occupation.manage');
    const isTestOrLocalEnvironment = environment.name === 'Test' || environment.name === 'Local';

    return [
      {
        label: 'link.profile',
        icon: 'fas fa-user-circle',
        routerLink: `/${AppRoutes.user}/${AppRoutes.profile}`,
      },
      {
        label: 'config.manage.title',
        icon: 'fas fa-cog',
        routerLink: `/${AppRoutes.config}`,
        hidden:
          (operatorPermission &&
            !from(this.ngxPermissionService.hasPermission(['translation.manage.tenant', 'role.manage.tenant']))) ||
          !(adminPermission || (operatorPermission && isTestOrLocalEnvironment)),
      },
      {
        label: 'link.logout',
        icon: 'fas fa-sign-in-alt',
        command: () => {
          this.authService
            .logout()
            .pipe(first())
            .subscribe(() => {
              this.router.navigate(['/', AppRoutes.auth, AppRoutes.operatorLogin]);
            });
        },
      },
    ];
  }
}
