import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-disruption-type-icon-widget',
  standalone: true,
  imports: [CommonModule],
  template: ` <i [ngClass]="item?.icon || ''"></i>`,
})
export class DisruptionTypeIconWidgetComponent extends ListRendererWidgetBase {}
