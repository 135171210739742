import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-on-hold-widget',
  standalone: true,
  imports: [CommonModule],
  template: `<i *ngIf="item.on_hold" class="fas fa-circle-pause on-hold-icon"></i>`,
  styleUrls: ['./on-hold-widget.component.scss'],
})
export class OnHoldWidgetComponent extends ListRendererWidgetBase {}
