import { ErrorHandler, NgModule } from '@angular/core';
import { AuthModule } from '@capturum/auth';
import { environment } from '@environments/environment';
import { ApiModule } from '@capturum/api';
import { LoginModule } from '@capturum/login';
import { SharedModule } from '@capturum/shared';
import {
  CapturumHttpCodeInterceptor,
  CompleteModule,
  ErrorMessageInterceptor,
  PermissionGuard,
} from '@capturum/complete';
import { NgxPermissionsModule, NgxPermissionsRestrictStubModule, NgxPermissionsService } from 'ngx-permissions';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from '@core/components/header/header.component';
import { NavigationBarComponent } from '@core/components/navigation-bar/navigation-bar.component';
import { UserMenuComponent } from '@core/components/user-menu/user-menu.component';
import { BasicLayoutComponent } from '@core/components/basic-layout/basic-layout.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { HorizontalNavbarModule } from '@capturum/ui/horizontal-navbar';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { getErrorHandler } from '@core/error-handlers/SentryErrorHandler';
import { CapturumSidebarMenuModule } from '@capturum/ui/sidebar-menu';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumInputModule } from '@capturum/ui/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { SharedModule as MetisSharedModule } from '@shared/shared.module';
import { OperatorLoginLayoutComponent } from '@core/components/operator-login-layout/operator-login-layout.component';
import { HttpCodeInterceptor } from '@core/interceptors/code.interceptor';

function onAuthError(): void {
  window.location.href = `auth/login`;
}

@NgModule({
  declarations: [
    HeaderComponent,
    NavigationBarComponent,
    UserMenuComponent,
    BasicLayoutComponent,
    OperatorLoginLayoutComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    HorizontalNavbarModule,
    RouterModule,
    OverlayPanelModule,
    NgxPermissionsModule.forRoot(),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: 'auth/login',
      userIdleConfig: {
        idleDuration: environment.idleDuration,
        timeout: environment.timeoutDuration,
      },
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError,
    }),
    LoginModule.forRoot({
      environment,
      productName: 'Metis',
      defaultLanguage: 'en',
    }),
    SharedModule.forRoot(),
    CompleteModule.forRoot(
      {
        indexedDbModels: [],
        databaseName: 'mytest-db',
        version: 1,
        environment: environment,
      },
      NgxPermissionsService,
      NgxPermissionsModule,
    ),
    CapturumSidebarMenuModule,
    CapturumSharedModule,
    CapturumButtonModule,
    CapturumInputModule,
    ReactiveFormsModule,
    ToastModule,
    MetisSharedModule,
    NgxPermissionsRestrictStubModule,
  ],
  providers: [
    CapturumHttpCodeInterceptor,
    PermissionGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CapturumHttpCodeInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCodeInterceptor,
      multi: true,
    },
  ],
  exports: [HeaderComponent, NavigationBarComponent, UserMenuComponent, BasicLayoutComponent],
})
export class CoreModule {}
