import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { Injectable } from '@angular/core';
import { SidebarService } from '@shared/services/sidebar.service';
import { SIDEBAR_CONFIG } from '@core/builders/configs/sidebar-action.config';

@Injectable({ providedIn: 'root' })
export class OpenSidebarAction implements ActionProvider {
  constructor(private sidebarService: SidebarService) {}

  public execute(options: ActionOptions, item: any, context?: Record<string, any>): void {
    const component = SIDEBAR_CONFIG[`${options.configurationKey}_${options.action.key}`];

    if (component) {
      this.sidebarService.open(component, {
        header: options.title,
        data: {
          item,
          ...options,
        },
      });
    }
  }
}
