import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

import { hmrBootstrap } from './hmr';
import 'hammerjs';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();
}

if (environment.sentryUrl) {
  if (environment.name === 'Test' || environment.name === 'Acceptance') {
    Sentry.init({
      dsn: environment.sentryUrl,
      normalizeDepth: 0,
      environment: environment.name,
      ignoreErrors: [
        'Non-Error exception captured',
      ],
      replaysSessionSampleRate: 0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      integrations: [new Sentry.Replay()],
    });
  } else {
    Sentry.init({
      dsn: environment.sentryUrl,
      normalizeDepth: 0,
      environment: environment.name,
      ignoreErrors: [
        'Non-Error exception captured',
      ],
    });
  }
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

 if (environment.hmr) {
    if (module['hot']) {
      hmrBootstrap(module, bootstrap);
    } else {
        bootstrap().catch((err) => console.log(err));
   }
 } else {
   bootstrap().catch((err) => console.log(err));
 }
