import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralConfig } from '@core/models/header-config.model';
import { GeneralConfigService } from '@core/services/general-config.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  public generalConfig$: Observable<GeneralConfig>;

  constructor(private readonly configService: GeneralConfigService) {}

  public ngOnInit(): void {
    this.generalConfig$ = this.configService.getGeneralConfig();
  }
}
