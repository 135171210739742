import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogLayoutComponent } from '@shared/components/dialog-layout/dialog-layout.component';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormModule } from '@capturum/formly';
import { SharedModule } from '@shared/shared.module';
import { BuildersListKey } from '@core/enums/builders-key.enum';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CountTimerComponent } from '@shared/components/count-timer/count-timer.component';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';

@Component({
  selector: 'app-components-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogLayoutComponent,
    CapturumListRendererModule,
    FormModule,
    SharedModule,
    CapturumFormRendererModule,
    CountTimerComponent,
    CapturumSkeletonModule,
  ],
  templateUrl: './components-dialog.component.html',
  styleUrls: ['./components-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ComponentsDialogComponent implements OnInit {
  public listKey: BuildersListKey = BuildersListKey.listComponents;
  public orderOperationId: string;

  constructor(public config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.orderOperationId = this.config.data.orderOperationId;
  }
}
