import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormModule } from '@capturum/formly';
import { SharedModule } from '@shared/shared.module';
import { BuildersFormKey, BuildersListKey } from '@core/enums/builders-key.enum';
import { CapturumFormRendererModule, FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { Observable, of } from 'rxjs';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CountTimerComponent } from '@shared/components/count-timer/count-timer.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { CapturumBuilderActionService, CapturumBuildersContextService } from '@capturum/builders/core';
import { RouterModule } from '@angular/router';
import { DestroyBase } from '@capturum/shared';

@Component({
  selector: 'app-add-disruption-type-value',
  standalone: true,
  imports: [
    CommonModule,
    FormModule,
    SharedModule,
    RouterModule,
    CapturumFormRendererModule,
    CapturumSkeletonModule,
    CapturumListRendererModule,
    CountTimerComponent,
  ],
  templateUrl: './add-disruption-type-value.component.html',
  styleUrls: ['./add-disruption-type-value.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddDisruptionTypeValueComponent extends DestroyBase implements OnInit {
  public formKey: BuildersFormKey = BuildersFormKey.formAdminDisruptionTypeValue;
  public disruptionValueKey = BuildersListKey.listAdminDisruptionTypeValues;
  public loading$: Observable<boolean> = of(true);
  public disruptionTypeId: string;
  public modelId: string;
  public readOnly: boolean;

  private contextService = inject(CapturumBuildersContextService);
  private config = inject(DynamicDialogConfig);
  private dialogRef = inject(DynamicDialogRef);
  private formRendererService = inject(FormRendererService);
  private formSaverService = inject(FormSaverService);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private actionService = inject(CapturumBuilderActionService);

  public ngOnInit(): void {
    this.modelId = this.config.data?.item?.id;
    this.readOnly = this.config.data?.action?.key === 'view';
    this.disruptionTypeId = this.contextService.getContextByKey(this.disruptionValueKey)?.disruption_type_id;
    this.loading$ = this.formRendererService.getFormConfigurationByKey(this.formKey).pipe(
      filter(Boolean),
      first(),
      map(() => {
        return false;
      }),
    );
  }

  public cancel(): void {
    this.dialogRef.close(true);
  }

  public submit(): void {
    this.formSaverService
      .submit(this.formKey, { disruption_type_id: this.disruptionTypeId })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const message = this.modelId
          ? 'hpm.admin.disruption-type-value.edit.save.success'
          : 'hpm.admin.disruption-type-value.add.save.success';

        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant(message),
        );

        this.cancel();

        // refresh list after submit
        this.actionService.broadcastActionExecuted(
          { key: 'refresh', type: 'refresh', options: { refresh: true } },
          null,
          null,
        );
      });
  }
}
