<div
  *ngIf="config$ | async as config"
  class="operator-login-screen {{ config?.displayInputsLabel ? 'display' : 'hide' }}-inputs-label"
  [style.background-image]="config?.backgroundImageUrl | cssUrl">
  <div class="operator-login-wrapper">
    <div class="operator-content-wrapper row">
      <div class="operator-content-left col-12 col-lg-6">
        <div class="logo" data-test="logo" [style.background-image]="config?.logoImageUrl | cssUrl"></div>
      </div>

      <div class="operator-content-right col-12 col-lg-6">
        <!-- View/routes wrapper-->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
