<nav>
  <div *ngIf="generalConfig$ | async as generalConfig" class="title-bar">
    <div class="equal-flex-col justify-content-start align-items-left">
      <a class="link-navigate-back-page" [routerLink]="generalConfig.backUrl">
        <span *ngIf="generalConfig.showNavigationBar && generalConfig.backUrl">
          <i class="fa fa-chevron-left"></i>&nbsp;&nbsp;{{ 'button.back-to-overview' | translate }}
        </span>

        <div *ngxPermissionsOnly="'hpm.admin.manage'" class="logo d-flex justify-content-start">
          <img alt="logo" [routerLink]="'admin-dashboard'" [src]="'emendis-logo.png' | assets : 'images'" />
        </div>
      </a>
    </div>

    <span class="equal-flex-col justify-content-center align-items-center">
      {{ generalConfig.mainHeader }}
    </span>

    <div class="equal-flex-col justify-content-end align-items-right flex-right">
      <app-user-menu></app-user-menu>
    </div>
  </div>
</nav>
