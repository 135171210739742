import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarControlService {
  private preventCloseSubject = new BehaviorSubject<boolean>(false);
  private hideSidebarSubject = new BehaviorSubject<boolean>(false);

  public setPreventClose(prevent: boolean): void {
    this.preventCloseSubject.next(prevent);
  }

  public shouldPreventClose(): boolean {
    return this.preventCloseSubject.value;
  }

  public setSidebarHide(): void {
    this.hideSidebarSubject.next(true);
  }

  public getSidebarHide(): Observable<boolean> {
    return this.hideSidebarSubject.asObservable();
  }
}
