import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-form-divider-title',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './form-divider-title.component.html',
  styleUrls: ['./form-divider-title.component.scss'],
})
export class FormDividerTitleComponent {
  @Input() public title: string;
  @Input() public icon: string;
}
