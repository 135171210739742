import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Input,
  OnInit,
  Type,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumDialogService, CapturumSharedModule, DynamicDialogcontentDirective } from '@capturum/ui/api';
import { SidebarModule } from 'primeng/sidebar';
import { SharedModule } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Sidebar } from 'primeng/sidebar/sidebar';
import { SidebarControlService } from '@shared/services/sidebar-control.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, SidebarModule, CapturumSharedModule, SharedModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements AfterViewInit, OnInit {
  @ViewChild(DynamicDialogcontentDirective)
  public insertionPoint: DynamicDialogcontentDirective;

  public componentRef: ComponentRef<any>;
  public childComponentType: Type<any>;
  public sidebarVisible = true;

  @Input()
  public title: string;

  constructor(
    public dialogConfig: DynamicDialogConfig,
    private dialogService: CapturumDialogService,
    private sidebarControlService: SidebarControlService,
  ) {}

  public ngOnInit(): void {
    this.title = this.dialogConfig.header;
  }

  public ngAfterViewInit(): void {
    this.loadChildComponent(this.childComponentType);
  }

  public close(): void {
    this.sidebarVisible = false;
  }

  public async handleHideSidebar(sidebar: Sidebar): Promise<void> {
    await this.sidebarControlService.setPreventClose(this.sidebarControlService.shouldPreventClose());
    this.sidebarControlService.setSidebarHide();

    if (this.sidebarControlService.shouldPreventClose()) {
      this.sidebarVisible = true;

      return; // Prevent closing the sidebar
    }

    sidebar.destroyModal();

    setTimeout(() => {
      this.componentRef.destroy();
      this.dialogService.dialogComponentRefMap.forEach((ref) => {
        ref.destroy();
      });
    }, 500);
  }

  public loadChildComponent(componentType: Type<any>): void {
    const viewContainerRef = this.insertionPoint.viewContainerRef;

    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentType);
  }
}
