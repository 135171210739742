<app-dialog-layout *ngIf="!viewQuality" [loading]="loading$ | async">
  <ng-container content>
    <cpb-form-renderer
      [context]="{ order_operation_id: orderOperationId }"
      [formKey]="formKey"
      [modelId]="modelId"
      [showHeader]="false"></cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <ng-container *ngIf="addQuality">
      <cap-button
        icon="fas fa-times"
        styleClass="popup-button cancel-button"
        [label]="'button.cancel' | translate"
        (onClick)="cancel()">
      </cap-button>

      <div class="d-flex">
        <cap-button
          icon="fas fa-list"
          styleClass="popup-button view-quality-button"
          [label]="'hpm.button.view-quality-inspections' | translate"
          (onClick)="viewQualityInspections()">
        </cap-button>

        <cap-button
          icon="fas fa-check"
          styleClass="popup-button confirm-button"
          [label]="'hpm.button.confirm' | translate"
          (onClick)="submit()">
        </cap-button>
      </div>
    </ng-container>

    <ng-container *ngIf="editQuality">
      <cap-button
        icon="fas fa-times"
        styleClass="popup-button cancel-button"
        [label]="'button.cancel' | translate"
        (onClick)="cancel()">
      </cap-button>

      <cap-button
        icon="fas fa-check"
        styleClass="popup-button confirm-button"
        [label]="'hpm.button.save' | translate"
        (onClick)="submit()">
      </cap-button>
    </ng-container>
  </ng-container>

  <ng-container skeleton>
    <div class="row w-100 mt-3">
      <div class="col-12">
        <cap-skeleton height="58px"></cap-skeleton>
      </div>
    </div>

    <div class="row w-100 mt-2">
      <div class="col-12">
        <cap-skeleton height="58px"></cap-skeleton>
      </div>
    </div>

    <div class="row w-100 mt-5 d-flex justify-content-between">
      <div class="col-2">
        <cap-skeleton height="60px"></cap-skeleton>
      </div>

      <div class="col-6">
        <div class="row">
          <div class="col-6 me-2">
            <cap-skeleton height="60px"></cap-skeleton>
          </div>

          <div class="col-6">
            <cap-skeleton height="60px"></cap-skeleton>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-dialog-layout>

<app-dialog-layout *ngIf="viewQuality" [loading]="false">
  <ng-container content>
    <cpb-list-renderer
      class="gray-lines"
      [context]="{ order_operation_id: orderOperationId }"
      [key]="listKey"></cpb-list-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button
      icon="fas fa-times"
      styleClass="popup-button cancel-button"
      [label]="'button.close' | translate"
      (onClick)="cancel()">
    </cap-button>

    <cap-button
      icon="fas fa-arrow-turn-down-left"
      styleClass="popup-button return-button"
      [label]="'hpm.button.return-to-quality-inspection' | translate"
      (onClick)="returnToAddQualityForm()">
    </cap-button>
  </ng-container>
</app-dialog-layout>
