<div class="d-flex align-items-center">
  <div class="user-info d-none d-lg-inline-block">
    <span class="d-block user-name">{{ user?.name }}</span>
    <span class="d-block user-email">{{ user?.email }}</span>
  </div>

  <div *ngxPermissionsOnly="'hpm.occupation.manage'" class="switch-user-container">
    <cap-button icon="fas fa-people-arrows" styleClass="primary" (onClick)="switchUser()"></cap-button>
  </div>

  <div class="menu-cta" (click)="menu?.toggle($event)">
    <i class="fas fa-ellipsis-v"></i>
  </div>

  <p-overlayPanel #menu appendTo="body" styleClass="cap-overlay-panel header-title-bar">
    <div class="user-menu">
      <ul class="user-menu__items">
        <ng-container *ngFor="let item of menuItems">
          <li *ngIf="!item?.hidden" (click)="executeCommand(item); menu?.hide()">
            <i [className]="item?.icon"></i>
            <span>{{ item?.label | translate }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </p-overlayPanel>
</div>
