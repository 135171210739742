import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@capturum/auth';
import { CompleteResolver, IndexedDbGuard, PermissionGuard } from '@capturum/complete';
import { BasicLayoutComponent } from '@core/components/basic-layout/basic-layout.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ConfigResolver } from '@core/resolvers/config.resolver';
import { AppRoutes } from '@core/enums/routes.enum';
import { LoginLayoutComponent } from '@capturum/login';
import { OperatorLoginLayoutComponent } from '@core/components/operator-login-layout/operator-login-layout.component';
import { MetisPublicTranslationResolver } from '@core/resolvers/metis-public-translation.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: AppRoutes.dashboard,
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard],
    resolve: {
      complete: CompleteResolver,
    },
    children: [
      {
        path: '',
        canActivate: [PermissionGuard],
        component: BasicLayoutComponent,
        children: [
          {
            path: '',
            redirectTo: AppRoutes.dashboard,
            pathMatch: 'full',
          },
          {
            path: AppRoutes.dashboard,
            canActivate: [NgxPermissionsGuard],
            loadChildren: () => {
              return import('./features/admin/admin-dashboard/admin-dashboard.module').then((m) => {
                return m.AdminDashboardModule;
              });
            },
            resolve: {
              generalConfig: ConfigResolver,
            },
            data: {
              generalConfig: {
                mainHeader: 'hpm.dashboard.title',
                showNavigationBar: false,
                backUrl: AppRoutes.dashboard,
              },
              permissions: {
                only: 'hpm.admin.manage',
                redirectTo: 'auth/login',
              },
            },
          },
          {
            path: 'tenants',
            loadChildren: () => {
              return import('./features/tenant/tenant.module').then((m) => {
                return m.TenantModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: 'tenant.manage',
                redirectTo: '/',
              },
            },
          },
          {
            path: 'roles',
            loadChildren: () => {
              return import('./features/role/role.module').then((m) => {
                return m.RoleModule;
              });
            },
            resolve: {
              generalConfig: ConfigResolver,
            },
            data: {
              permissions: {
                only: 'role.manage.tenant',
                redirectTo: '/',
              },
              generalConfig: {
                mainHeader: 'hpm.roles.title',
                showNavigationBar: true,
              },
            },
          },
          {
            path: 'translations',
            loadChildren: () => {
              return import('./features/translation/translation.module').then((m) => {
                return m.TranslationModule;
              });
            },
            resolve: {
              generalConfig: ConfigResolver,
            },
            data: {
              generalConfig: {
                mainHeader: 'hpm.translations.title',
                showNavigationBar: true,
              },
            },
          },
          {
            path: 'users',
            loadChildren: () => {
              return import('./features/user/user.module').then((m) => {
                return m.UserModule;
              });
            },
            resolve: {
              generalConfig: ConfigResolver,
            },
            data: {
              generalConfig: {
                mainHeader: 'hpm.users.title',
                showNavigationBar: true,
              },
            },
          },
          {
            path: 'base-data',
            loadChildren: () => {
              return import('./features/base-data/base-data.module').then((m) => {
                return m.BaseDataModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            resolve: {
              generalConfig: ConfigResolver,
            },
            data: {
              permissions: {
                only: 'base-data-value.manage.tenant',
                redirectTo: '/',
              },
              generalConfig: {
                mainHeader: 'hpm.base-data.title',
                showNavigationBar: true,
              },
            },
          },
          {
            path: 'settings',
            loadChildren: () => {
              return import('./features/settings/settings.module').then((m) => {
                return m.SettingsModule;
              });
            },
            resolve: {
              generalConfig: ConfigResolver,
            },
            data: {
              generalConfig: {
                mainHeader: 'hpm.settings.title',
                showNavigationBar: true,
              },
            },
          },
          {
            path: 'config',
            loadChildren: () => {
              return import('./features/config/config.module').then((m) => {
                return m.ConfigModule;
              });
            },
            resolve: {
              generalConfig: ConfigResolver,
            },
            data: {
              generalConfig: {
                mainHeader: 'hpm.config.title',
                showNavigationBar: true,
              },
            },
          },
          {
            path: 'builders',
            loadChildren: () => {
              return import('@capturum/builders/pages').then((m) => {
                return m.CapturumBuilderPagesModule;
              });
            },
            resolve: {
              generalConfig: ConfigResolver,
            },
            data: {
              generalConfig: {
                mainHeader: 'hpm.builders.title',
                showNavigationBar: true,
              },
            },
          },
          {
            path: '',
            loadChildren: () => {
              return import('@capturum/complete').then((complete) => {
                return complete.CompleteModule;
              });
            },
          },
        ],
      },
    ],
  },
  {
    path: AppRoutes.auth,
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: MetisPublicTranslationResolver,
    },
    component: LoginLayoutComponent,
    loadChildren: () => {
      return import('./features/admin/auth/auth.module').then((m) => {
        return m.AuthModule;
      });
    },
  },
  {
    path: AppRoutes.auth,
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: MetisPublicTranslationResolver,
    },
    data: {
      generalConfig: {
        mainHeader: 'hpm.builders.title',
        showNavigationBar: true,
      },
    },
    component: OperatorLoginLayoutComponent,
    loadChildren: () => {
      return import('./features/operator/auth-operator/auth-operator.module').then((m) => {
        return m.AuthOperatorModule;
      });
    },
  },
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard, PermissionGuard],
    resolve: {
      complete: CompleteResolver,
    },
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: AppRoutes.orderOperations,
        pathMatch: 'full',
      },
      {
        path: AppRoutes.orderOperations,
        canActivate: [NgxPermissionsGuard],
        resolve: [ConfigResolver],
        loadChildren: () => {
          return import('./features/operator/order-operations/routes').then((m) => {
            return m.default;
          });
        },
        data: {
          permissions: {
            only: 'hpm.order.show',
            redirectTo: 'auth/operator-login',
          },
          generalConfig: {
            mainHeader: '',
            showNavigationBar: true,
          },
        },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'auth/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
