import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogLayoutComponent } from '@shared/components/dialog-layout/dialog-layout.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormModule } from '@capturum/formly';
import { SharedModule } from '@shared/shared.module';
import { BuildersFormKey } from '@core/enums/builders-key.enum';
import { CapturumFormRendererModule, FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { Observable, of, switchMap } from 'rxjs';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { filter, first, map } from 'rxjs/operators';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-completed-order-operation-disruptions',
  standalone: true,
  imports: [
    CommonModule,
    DialogLayoutComponent,
    FormModule,
    SharedModule,
    CapturumFormRendererModule,
    CapturumSkeletonModule,
  ],
  templateUrl: './add-completed-order-operation-disruptions.component.html',
  styleUrls: ['./add-completed-order-operation-disruptions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddCompletedOrderOperationDisruptionsComponent implements OnInit {
  public formKey: BuildersFormKey = BuildersFormKey.formCompletedOrderOperationDisruption;
  public orderOperationId: string;
  public stationId: string;
  public disruptionId: string;
  public loading$: Observable<boolean> = of(true);

  constructor(
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formRendererService: FormRendererService,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    const { orderOperationId, stationId, disruptionId } = this.config.data;

    this.orderOperationId = orderOperationId;
    this.stationId = stationId;

    if (disruptionId) {
      this.disruptionId = disruptionId;
    }

    this.loading$ = this.formRendererService.getFormConfigurationByKey(this.formKey).pipe(
      filter(Boolean),
      first(),
      map(() => {
        return false;
      }),
    );
  }

  public cancel(): void {
    this.dialogRef.close(true);
  }

  public submit(): void {
    this.formRendererService
      .getFormValueByKey(this.formKey)
      .pipe(
        switchMap((formData) => {
          const adjustedValue = {
            ...formData,
            order_operation_id: this.orderOperationId,
          };

          return this.formSaverService.submit(this.formKey, adjustedValue);
        }),
        first(),
      )
      .subscribe(() => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('hpm.order-operations.view-box.add-disruption.save.success'),
        );

        this.cancel();
      });
  }
}
