import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogLayoutComponent } from '@shared/components/dialog-layout/dialog-layout.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormModule } from '@capturum/formly';
import { SharedModule } from '@shared/shared.module';
import { BuildersFormKey } from '@core/enums/builders-key.enum';
import { CapturumFormRendererModule, FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { filter, first, map } from 'rxjs/operators';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';

@Component({
  selector: 'app-quality-deviation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogLayoutComponent,
    FormModule,
    SharedModule,
    CapturumFormRendererModule,
    CapturumSkeletonModule,
  ],
  templateUrl: './quality-deviation-dialog.component.html',
  styleUrls: ['./quality-deviation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QualityDeviationDialogComponent implements OnInit {
  public formKey: BuildersFormKey = BuildersFormKey.formQualityDeviation;
  public loading$: Observable<boolean> = of(true);
  public orderOperationId: string;

  constructor(
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formRendererService: FormRendererService,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.orderOperationId = this.config?.data?.orderOperationId;

    this.loading$ = this.formRendererService.getFormConfigurationByKey(this.formKey).pipe(
      filter(Boolean),
      first(),
      map(() => {
        return false;
      }),
    );
  }

  public cancel(): void {
    this.dialogRef.close(true);
  }

  public submit(): void {
    combineLatest([
      this.formRendererService.getFormValueByKey(this.formKey),
      this.formRendererService.getFormConfigurationByKey(this.formKey),
    ])
      .pipe(
        switchMap(([value, config]) => {
          const adjustedValue = {
            ...value,
            order_operation_id: this.orderOperationId,
          };

          return this.formSaverService.submitToBackend(config.submit.endpoint, adjustedValue, this.formKey);
        }),
        first(),
      )
      .subscribe((response) => {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('hpm.order-operations.open-box.quality-deviation.save.success'),
        );

        this.cancel();
      });
  }
}
