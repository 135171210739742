import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogLayoutComponent } from '@shared/components/dialog-layout/dialog-layout.component';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormModule } from '@capturum/formly';
import { SharedModule } from '@shared/shared.module';
import { BuildersFormKey } from '@core/enums/builders-key.enum';
import { CapturumFormRendererModule, FormRendererService } from '@capturum/builders/form-renderer';
import { CountTimerComponent } from '@shared/components/count-timer/count-timer.component';
import { OrderOperationService } from '@shared/services/order-operation.service';
import { Observable, of, switchMap } from 'rxjs';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { filter, first, map } from 'rxjs/operators';
import { DoubleClickDirective } from '@shared/directives/double-click.directive';

@Component({
  selector: 'app-add-disruptions-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogLayoutComponent,
    CapturumListRendererModule,
    FormModule,
    SharedModule,
    CapturumFormRendererModule,
    CountTimerComponent,
    CapturumSkeletonModule,
    DoubleClickDirective,
  ],
  templateUrl: './add-disruptions-dialog.component.html',
  styleUrls: ['./add-disruptions-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddDisruptionsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public formKey: BuildersFormKey = BuildersFormKey.formOrderOperationDisruption;
  public elapsedTimeInSeconds = 0;
  public orderOperationId: string;
  public stationId: string;
  public isGlobalAction: boolean;
  public loading$: Observable<boolean> = of(true);
  private activityTimer: NodeJS.Timer;
  private startTime: number;

  constructor(
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formRendererService: FormRendererService,
    public orderOperationService: OrderOperationService,
  ) {
    this.startTime = Date.now();
  }

  public ngOnInit(): void {
    const { orderOperationId, stationId, globalAction } = this.config.data;

    this.orderOperationId = orderOperationId;
    this.stationId = stationId;
    this.isGlobalAction = globalAction;

    this.loading$ = this.formRendererService.getFormConfigurationByKey(this.formKey).pipe(
      filter(Boolean),
      first(),
      map(() => {
        return false;
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.startTimer();
  }

  private startTimer(): void {
    this.activityTimer = setTimeout(() => {
      this.elapsedTimeInSeconds = Math.floor((Date.now() - this.startTime) / 1000);

      this.startTimer();
    }, 1000);
  }

  public cancel(): void {
    const method = this.isGlobalAction
      ? this.orderOperationService.globalCancelOperation()
      : this.orderOperationService.cancelOperation(this.orderOperationId);

    method.subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  public submit(): void {
    this.formRendererService
      .getFormValueByKey(this.formKey)
      .pipe(
        switchMap((formData) => {
          const payload = {
            remark: formData.remark,
            disruption_value_id: formData.disruption_value_id,
          };

          return this.isGlobalAction
            ? this.orderOperationService.globalResumeOperation(payload)
            : this.orderOperationService.resumeOperation(payload, this.orderOperationId);
        }),
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  public ngOnDestroy(): void {
    clearInterval(this.activityTimer);
  }
}
