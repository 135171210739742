import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CapturumFormRendererComponent,
  CapturumFormRendererModule,
  FormRendererService,
} from '@capturum/builders/form-renderer';
import { BuildersFormKey } from '@core/enums/builders-key.enum';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { SidebarLayoutComponent } from '@shared/components/sidebar-layout/sidebar-layout.component';
import { OrderOperationService } from '@shared/services/order-operation.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { CapturumDialogService, LocalStorageService } from '@capturum/ui/api';
import { AddDisruptionsDialogComponent } from '../add-disruptions-dialog/add-disruptions-dialog.component';
import { CapturumListRendererModule, ListRendererService } from '@capturum/builders/list-renderer';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { SharedModule } from '@shared/shared.module';
import { first } from 'rxjs/operators';
import { Observable, switchMap } from 'rxjs';
import { ComponentsDialogComponent } from '../components-dialog/components-dialog.component';
import { OrderOperationDetails, OrderOperationDisruptions } from '@core/interfaces/order-operation.interface';
import { StationStateService } from '@shared/services/station-state.service';
import { QualityDeviationDialogComponent } from '../quality-deviation-dialog/quality-deviation-dialog.component';
import { QualityInspectionDialogComponent } from '../quality-inspection-dialog/quality-inspection-dialog.component';
import { FormDividerTitleComponent } from '@shared/components/form-divider-title/form-divider-title.component';
import { StopOrderOperationPopupComponent } from '../stop-order-operation-popup/stop-order-operation-popup.component';
import { SetOccupationPopupComponent } from '@shared/components/set-occupation-popup/set-occupation-popup.component';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';

@Component({
  selector: 'app-process-order',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CapturumFormRendererModule,
    CapturumButtonModule,
    SidebarLayoutComponent,
    CapturumListRendererModule,
    CapturumSkeletonModule,
    SharedModule,
    FormDividerTitleComponent,
  ],
  styleUrls: ['./process-order.component.scss'],
  templateUrl: './process-order.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ProcessOrderComponent implements OnInit {
  public orderDetailsKey: BuildersFormKey = BuildersFormKey.formOrderDetails;
  public orderOperationDetailKey: BuildersFormKey = BuildersFormKey.formOrderOperationDetails;
  public startedOrderOperation = false;
  @ViewChild('orderOperationForm')
  public orderOperationForm: CapturumFormRendererComponent;

  public stoppedAt: Date;
  public isStartedProcess = false;
  public isOnHoldProcess = false;
  public orderOperationId: string;
  public stationId: string;
  public loading$: Observable<boolean>;
  public disruptions$: Observable<OrderOperationDisruptions[]>;
  public orderOperation$: Observable<OrderOperationDetails>;

  constructor(
    public config: DynamicDialogConfig,
    public orderOperationService: OrderOperationService,
    private listRendererService: ListRendererService,
    private sidebarService: SidebarService,
    private dialogService: CapturumDialogService,
    private formRendererService: FormRendererService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private stationStateService: StationStateService,
    private localStorageService: LocalStorageService,
  ) {}

  public ngOnInit(): void {
    if (this.config?.data?.item?.started_time !== '00:00') {
      this.startedOrderOperation = true;
    }

    this.orderOperationId = this.config?.data?.item?.id;
    this.isOnHoldProcess = this.config?.data?.item?.on_hold;
    this.stationId = this.stationStateService.getStation().id;
    this.loading$ = this.formRendererService.getSourceValueByKey(this.orderDetailsKey);
    this.orderOperation$ = this.formRendererService.getSourceValueByKey(this.orderOperationDetailKey);
    this.getDisruptions();
  }

  public qualityInspection(): void {
    this.dialogService.open(QualityInspectionDialogComponent, {
      header: this.translateService.instant('hpm.order-operations.open-box.quality-inspection.title'),
      styleClass: 'quality-inspection',
      data: {
        orderOperationId: this.orderOperationId,
      },
    });
  }

  public qualityDeviation(): void {
    this.dialogService.open(QualityDeviationDialogComponent, {
      header: this.translateService.instant('hpm.order-operations.open-box.quality-deviation.title'),
      styleClass: 'quality-deviation',
      data: {
        orderOperationId: this.orderOperationId,
      },
    });
  }

  public viewComponents(): void {
    this.dialogService.open(ComponentsDialogComponent, {
      header: this.translateService.instant('hpm.order-operations.open-box.order-details.components.title'),
      styleClass: 'components',
      data: {
        orderOperationId: this.orderOperationId,
        stationId: this.stationId,
      },
    });
  }

  public start(): void {
    const dialogRef = this.dialogService.open(SetOccupationPopupComponent, {
      header: this.translateService.instant('hpm.set-occupation.title'),
      styleClass: 'responsive-dialog padding-0 set-occupation',
    });

    dialogRef.onClose
      .pipe(
        first(),
        switchMap(() => {
          return this.orderOperationService.startOperation({ started_at: new Date() }, this.orderOperationId);
        }),
      )
      .subscribe((orderOperation) => {
        if (orderOperation) {
          this.localStorageService.setItem(LocalStorageKey.initialOccupation, {
            isInitialOccupation: false,
            fte: orderOperation.occupation,
          });

          this.orderOperationForm?.getFormSourceData();
          this.startedOrderOperation = true;
          this.listRendererService.refreshTable();
        }
      });
  }

  public pause(): void {
    this.orderOperationService.pauseOperation(this.orderOperationId).subscribe((result) => {
      if (result) {
        const dialogRef = this.dialogService.open(AddDisruptionsDialogComponent, {
          header: this.translateService.instant('hpm.order-operations.open-box.order-details.pause.title'),
          styleClass: 'add-disruptions',
          data: {
            orderOperationId: this.orderOperationId,
            stationId: this.stationId,
            globalAction: false,
          },
        });

        dialogRef.onClose.pipe(first()).subscribe((res) => {
          this.getDisruptions();
          this.cdr.detectChanges();
        });
      }
    });
  }

  public onHold(): void {
    this.orderOperationService.onHoldOperation(this.orderOperationId).subscribe((result) => {
      if (result) {
        this.isOnHoldProcess = result.on_hold;

        this.cdr.detectChanges();
        this.listRendererService.refreshTable();
      }
    });
  }

  public resumeOnHoldOperation(): void {
    const dialogRef = this.dialogService.open(SetOccupationPopupComponent, {
      header: this.translateService.instant('hpm.set-occupation.title'),
      styleClass: 'responsive-dialog padding-0 set-occupation',
    });

    dialogRef.onClose
      .pipe(
        first(),
        switchMap(() => {
          return this.orderOperationService.resumeOnHoldOperation(this.orderOperationId);
        }),
      )
      .subscribe((orderOperation) => {
        if (orderOperation) {
          this.localStorageService.setItem(LocalStorageKey.initialOccupation, {
            isInitialOccupation: false,
            fte: orderOperation.occupation,
          });

          this.orderOperationForm?.getFormSourceData();
          this.isOnHoldProcess = orderOperation.on_hold;
          this.listRendererService.refreshTable();
        }
      });
  }

  public stop(): void {
    if (!this.isStartedProcess) {
      this.stoppedAt = new Date();
    }

    this.orderOperationService.stopOperation({ finished_at: this.stoppedAt }, this.orderOperationId).subscribe(
      () => {},
      (error) => {
        if (error?.error?.warnings) {
          const dialogRef = this.dialogService.open(StopOrderOperationPopupComponent, {
            header: this.translateService.instant('confirm.title'),
            styleClass: 'stop-order-operation',
            data: {
              orderOperationId: this.orderOperationId,
              finished_at: this.stoppedAt,
              warnings: error.error.warnings,
            },
          });

          dialogRef.onClose.pipe(first()).subscribe((wasForced) => {
            if (wasForced) {
              this.sidebarService.close();
              this.listRendererService.refreshTable();
            }
          });
        } else if (error?.error?.errors) {
          this.isStartedProcess = true;
        }
      },
    );
  }

  public getDisruptions(): void {
    this.disruptions$ = this.orderOperationService.getOrderOperationDisruptions(this.orderOperationId);
  }
}
