<app-sidebar-layout [loading]="loading$ | async">
  <ng-container content>
    <div class="order-details">
      <app-form-divider-title
        [icon]="'fa fa-info-circle'"
        [title]="'hpm.order-operations.view-box.order-details.title'">
      </app-form-divider-title>

      <div class="content">
        <cpb-form-renderer [formKey]="orderDetailsKey" [modelId]="config?.data?.item?.order_id" [showHeader]="false">
        </cpb-form-renderer>
      </div>
    </div>

    <div class="order-duration mt-4">
      <app-form-divider-title [icon]="'fa fa-clock'" [title]="'hpm.order-operations.view-box.duration.title'">
      </app-form-divider-title>

      <div class="content">
        <cpb-form-renderer [formKey]="orderOperationDetailKey" [modelId]="config?.data?.item?.id" [showHeader]="false">
        </cpb-form-renderer>
      </div>
    </div>

    <ng-container *ngIf="disruptions$ | async as disruptions">
      <div *ngIf="disruptions?.length" class="disruptions mt-3">
        <app-form-divider-title
          [icon]="'fa fa-triangle-exclamation'"
          [title]="'hpm.order-operations.view-box.disruption.title'">
        </app-form-divider-title>

        <div *ngFor="let disruption of disruptions" class="content disruption">
          <div class="row">
            <div class="col-10">
              <div class="row">
                <div class="disruption-title">
                  <span><i class="{{ disruption.icon }} me-2 disruption-icon"></i>{{ disruption.title }}</span>
                </div>
                <div class="disruption-value">
                  <span>{{ disruption.details }}</span>
                </div>
              </div>

              <div class="row">
                <div class="disruption-title">
                  <span>{{ 'hpm.order-operations.view-box.order-details.disruption.duration.label' | translate }}</span>
                </div>
                <div class="disruption-value">
                  <span>{{ disruption.duration }}</span>
                </div>
              </div>
            </div>

            <div *ngIf="disruption.is_editable" class="col-2 d-flex justify-content-end align-items-center">
              <i class="fas fa-pencil edit-disruption" (click)="editDisruption(disruption.id)"></i>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container buttons>
    <div class="d-flex justify-content-end">
      <cap-button
        class="add-disruption-button"
        data-test="add-disruption"
        icon="fas fa-triangle-exclamation"
        [label]="'hpm.order-operations.view-box.add-disruption.button' | translate"
        (onClick)="addDisruption()">
      </cap-button>
    </div>
  </ng-container>

  <ng-container skeleton>
    <div *ngFor="let card of [1, 2, 3, 4]" class="row w-100 mt-3">
      <cap-skeleton height="45px" width="448px"></cap-skeleton>
    </div>

    <div *ngFor="let button of [1, 2, 3]" class="row w-100 mt-3">
      <cap-skeleton height="46px" width="230px"></cap-skeleton>
    </div>

    <div *ngFor="let card of [1, 2, 3, 4]" class="row w-100 mt-3">
      <cap-skeleton height="45px" width="448px"></cap-skeleton>
    </div>
  </ng-container>
</app-sidebar-layout>
