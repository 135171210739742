import { BUILD_TIMESTAMP } from '@environments/timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Metis',
  production: false,
  hmr: false,
  name: 'Acceptance',
  baseUrl: 'https://obtuse-humvee.k8s-accept.emendis.nl/api',
  sentryUrl: 'https://0d0068be8fd14218aad787edbb922002@o4504360297496576.ingest.sentry.io/4504599634116608',
  databaseName: 'metis',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  url: 'http://localhost:4200',
  idleDuration: 3600, // 60min
  timeoutDuration: 600, // 10min
};
