import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormModule } from '@capturum/formly';
import { SharedModule } from '@shared/shared.module';
import { BuildersFormKey } from '@core/enums/builders-key.enum';
import { CapturumFormRendererModule, FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { Observable, of } from 'rxjs';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CountTimerComponent } from '@shared/components/count-timer/count-timer.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { DestroyBase } from '@capturum/shared';

@Component({
  selector: 'app-add-disruption-type',
  standalone: true,
  imports: [
    CommonModule,
    FormModule,
    SharedModule,
    CapturumFormRendererModule,
    CapturumSkeletonModule,
    CapturumListRendererModule,
    CountTimerComponent,
  ],
  templateUrl: './add-disruption-type.component.html',
  styleUrls: ['./add-disruption-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddDisruptionTypeComponent extends DestroyBase implements OnInit {
  public formKey: BuildersFormKey = BuildersFormKey.formAdminDisruptionType;
  public loading$: Observable<boolean> = of(true);
  public modelId: string;

  constructor(
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formRendererService: FormRendererService,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private actionService: CapturumBuilderActionService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modelId = this.config.data?.item?.id;
    this.loading$ = this.formRendererService.getFormConfigurationByKey(this.formKey).pipe(
      filter(Boolean),
      first(),
      map(() => {
        return false;
      }),
    );
  }

  public cancel(): void {
    this.dialogRef.close(true);
  }

  public submit(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const message = this.modelId
          ? 'hpm.admin.disruption-type.edit.save.success'
          : 'hpm.admin.disruption-type.add.save.success';

        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant(message),
        );

        this.cancel();

        // refresh list after submit
        this.actionService.broadcastActionExecuted(
          { key: 'refresh', type: 'refresh', options: { refresh: true } },
          null,
          null,
        );
      });
  }
}
