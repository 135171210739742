import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-view-details-widget',
  standalone: true,
  imports: [CommonModule],
  template: `<i class="fas fa-chevron-right details-arrow"></i>`,
  styleUrls: ['./view-details-widget.component.scss'],
})
export class ViewDetailsWidgetComponent extends ListRendererWidgetBase {}
