import { Component, ViewEncapsulation } from '@angular/core';
import { LoginLayoutComponent } from '@capturum/login';

@Component({
  selector: 'app-login',
  templateUrl: 'operator-login-layout.component.html',
  styleUrls: ['./operator-login-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OperatorLoginLayoutComponent extends LoginLayoutComponent {}
